@if(galleryItems){
  <div class='gallery' [class.generic]='isGeneric()'
       cdkDropList
       cdkDropListOrientation="horizontal"
       [cdkDropListData]="galleryItems"
       (cdkDropListDropped)="drop($event, draggedImage)"
  >
    @for(item of galleryItems; track trackByFn){
      <div class='image'
           cdkDrag
           (cdkDragStarted)="draggedImage = item"
           [class.full]="($index + 1) % 3 === 0 && (event?.eventTemplate?.layout === EventTemplateLayout.ONE_COLUMN_ELEGANT || (event?.eventTemplate?.layout === EventTemplateLayout.ONE_COLUMN_MODERN && !isDesktop))">
        <img [src]='item.url' alt='Gallery Image' [ngStyle]="{
        height: limitHeight
        }"/>
        @if(isEdit){
          <div class="update-actions-menu" [matMenuTriggerFor]="menu">
            <div class="menu-trigger">
              <i class="far fa-ellipsis-v"></i>
            </div>
            <mat-menu #menu="matMenu" class="table-actions-container">
              <button
                mat-menu-item
                (click)="onUpdate(UpdateActions.EDIT, item)"
              >
                <span class="menu-item-content">
                  <i class="far fa-pen"></i>
                  <span class="text">{{
                      "APP.EDIT" | translate | camelCase
                    }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="onUpdate(UpdateActions.DELETE, item)"
              >
                <span class="menu-item-content">
                  <i class="far fa-trash"></i>
                  <span class="text">{{
                      "APP.DELETE" | translate | camelCase
                    }}</span>
                </span>
              </button>
            </mat-menu>
          </div>
        }
      </div>
    }
  </div>

}
