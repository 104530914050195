import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { EventTemplateLayout } from '@models/design-templates/event-template-layout.enum';
import { EventAsset } from '@models/events/event-asset.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { EventStore } from '@services/stores/event.store';
import { CamelCasePipe } from '../../../../pipes/camel-case.pipe';
import { CommonModule, NgForOf } from '@angular/common';
import { UpdateActions } from '@models/shared/enums/update-actions.enum';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-event-section-gallery',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    CamelCasePipe,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './event-section-gallery.component.html',
  styleUrl: './event-section-gallery.component.scss',
})
export class EventSectionGalleryComponent {
  @Input() isEdit?: boolean = false;
  @Input() limitHeight: string = 'auto';
  event?: Event;
  galleryItems?: EventAsset[];
  isDesktop?: boolean = false;
  draggedImage?: EventAsset;
  @Output() onEdit = new EventEmitter();

  constructor(
    private eventStore: EventStore,
    private eventService: EventService,
    private screenWidthService: ScreenWidthService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      this.event = event;
      if (this.event?.eventTemplate?.eventAssets) {
        this.galleryItems = this.event.eventTemplate.eventAssets
          .filter((x) => x.isGalleryAsset)
          //.filter((asset) => asset.isGalleryAsset)
          .sort((a, b) => a.order - b.order);
      }
    });

    this.screenWidthService
      .isOver900()
      .subscribe((isDesktop) => (this.isDesktop = isDesktop));
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  drop(event: CdkDragDrop<EventAsset[]>, draggedImage?: EventAsset) {
    if (this.galleryItems) {
      moveItemInArray(
        this.galleryItems,
        event.previousIndex,
        event.currentIndex,
      );
    }

    if (this.draggedImage) {
      this.eventService
        .updateEvent(this.event?.id, {
          eventAsset: {
            id: this.draggedImage.id,
            isGalleryAsset: true,
            order: event.currentIndex,
          },
        })
        .subscribe(() => {
          if (this.event) this.eventStore.refreshEvent(this.event.uri);
        });
    }
  }

  onUpdate(action: UpdateActions, item: EventAsset) {
    if (action === UpdateActions.EDIT) {
      this.onEdit.emit({
        eventAsset: item,
      });
    } else if (action === UpdateActions.DELETE) {
      this.removeItem(item);
    }
  }

  isGeneric() {
    return (
      this.event?.type === EventType.GENERIC_EVENT ||
      this.event?.type === EventType.GENERIC_LANDINGPAGE
    );
  }

  removeItem(item: EventAsset) {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_TITLE',
        message: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_CONFIRMATION',
        yesLabel: 'APP.YES',
        font: this.event?.eventTemplate?.font,
        isDeleteDialog: true,
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        this.eventService
          .updateEvent(this.event?.id, {
            eventAssetDeleteId: item.id,
          })
          .subscribe({
            next: () => {
              if (this.event?.uri) {
                this.eventStore.refreshEvent(this.event?.uri);
              }
            },
            error: () => {
              this.notificationService.error(
                this.translateService.instant(
                  'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ERROR',
                ),
              );
            },
          });
      }
    });
  }

  protected readonly UpdateActions = UpdateActions;
  protected readonly EventTemplateLayout = EventTemplateLayout;
}
