<mat-drawer-container class="event-settings-container" autosize>
  <mat-drawer
    class="event-settings-sidenav"
    mode="side"
    position="end"
    [opened]="sidebarService.sidebarEventSectionDetailsState$ | async"
  >
    <div class="sticky">
      <div class="settings-title">
        <div class="title-container">
          <p color="primary">
            {{ getTitle() | translate }}
          </p>
        </div>
        <div (click)="toggleSidebar()" class="close-button">
          <i class="fal fa-times-circle close-drawer"></i>
        </div>
      </div>
      <hr class="hr" />
    </div>

    @if(form && configurableObject){
      <div class='content' [formGroup]="form">
        @if(configurableObject.section?.type === EventSectionType.TIMELINE){
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.TITLE" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="title"
              placeholder=""
            />
          </mat-form-field>
          @if(!hideDescription){
            <mat-form-field
              class="flex-half-shrink flex-input w-100"
              appearance="outline"
            >
              <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.DESCRIPTION" | translate }}</mat-label>
              <textarea
                #description
                cdkTextareaAutosize
                matInput
                formControlName="description"
                maxlength="200"
                placeholder="{{
           'APP.ADVANCED_ONE_COLUMN_EVENT.DESCRIPTION'  | translate
          }}"
              ></textarea>
            </mat-form-field>
          }
          <div class="time-range-cont">
            <app-time-picker
              formControlName="timeFrom"
              [interval]="15"
              [format24Hours]="true"
              [isList]="false"
              label="APP.START_TIME"
            ></app-time-picker>
            <div>{{ "APP.TO" | translate }}</div>
            <app-time-picker
              formControlName="timeTo"
              [interval]="15"
              [format24Hours]="true"
              [isList]="false"
              label="APP.END_TIME"
            ></app-time-picker>
          </div>
        }

        @if(configurableObject.section?.type === EventSectionType.TESTIMONIALS){
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.USER_NAME" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="userName"
              placeholder=""
            />
          </mat-form-field>
          @if(event?.isGeneric()){
            <mat-form-field
              class="flex-half-shrink flex-input w-100"
              appearance="outline"
            >
              <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.USER_CITY" | translate }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="userCity"
                placeholder=""
              />
            </mat-form-field>
          }
          @if(event?.type === EventType.WEDDINGPAGE || event?.isGeneric()){
            <mat-form-field
              class="flex-half-shrink flex-input w-100"
              appearance="outline"
            >
              <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.DESCRIPTION" | translate }}</mat-label>
              <textarea
                #description
                cdkTextareaAutosize
                matInput
                formControlName="description"
                placeholder="{{
           'APP.ADVANCED_ONE_COLUMN_EVENT.DESCRIPTION'  | translate
          }}"
              ></textarea>
            </mat-form-field>
          }@else{
            <mat-form-field
              class="flex-half-shrink flex-input w-100"
              appearance="outline"
            >
              <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.JOB_TITLE" | translate }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="userJobTitle"
                placeholder=""
              />
            </mat-form-field>
          }

          <div class="file-container">
            <app-file-uploader
              [aspectRatio]="300 / 157"
              [fluidWidth]="true"
              [resizeToWidth]="1200"
              [useImagePreviewComponent]="true"
              [uploaderType]="'IMAGE'"
              [descriptionAction]='"APP.ADVANCED_ONE_COLUMN_EVENT.UPLOAD_USER_AVATAR_IMAGE"'
              [selectedFile]="form.controls['userAvatarAsset'].value"
              (fileChanged)="onImageChanged($event)"
            >
            </app-file-uploader>
          </div>
        }

        @if(configurableObject.section?.type === EventSectionType.NEWS){
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.TITLE" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="title"
              placeholder=""
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.DESCRIPTION" | translate }}</mat-label>
            <textarea
              #content
              cdkTextareaAutosize
              matInput
              formControlName="content"
              rows="5"
              placeholder=""
            ></textarea>
          </mat-form-field>
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.LINK" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="link"
              placeholder=""
            />
          </mat-form-field>
        }

        @if(configurableObject.section?.type === EventSectionType.FAQ){
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.QUESTION" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="question"
              placeholder=""
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.ANSWER" | translate }}</mat-label>
            <textarea
              #answer
              cdkTextareaAutosize
              matInput
              formControlName="answer"
              rows="5"
              maxlength="350"
              placeholder=""
            ></textarea>
            <span matTextSuffix>{{ answer.value.length || "" }} | 350</span>
          </mat-form-field>
        }

        @if (configurableObject.section?.type === EventSectionType.SPONSORS){
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.LINK" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="link"
              placeholder=""
            />
          </mat-form-field>
          <div class="file-container">
            <app-file-uploader
              [aspectRatio]="300 / 157"
              [fluidWidth]="true"
              [resizeToWidth]="1200"
              [useImagePreviewComponent]="true"
              [uploaderType]="'IMAGE'"
              [descriptionAction]='"APP.ADVANCED_ONE_COLUMN_EVENT.UPLOAD_SPONSOR_IMAGE"'
              [selectedFile]="form.controls['imageAsset'].value"
              (fileChanged)="onImageChanged($event)"
            >
            </app-file-uploader>
          </div>
        }

        @if (configurableObject.section?.type === EventSectionType.WISHLIST){
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.LINK" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="link"
              placeholder=""
            />
          </mat-form-field>
          <div class="file-container">
            <app-file-uploader
              [aspectRatio]="300 / 157"
              [fluidWidth]="true"
              [resizeToWidth]="1200"
              [useImagePreviewComponent]="true"
              [uploaderType]="'IMAGE'"
              [descriptionAction]='"APP.ADVANCED_ONE_COLUMN_EVENT.UPLOAD_IMAGE"'
              [selectedFile]="form.controls['pictureAsset'].value"
              (fileChanged)="onImageChanged($event)"
            >
            </app-file-uploader>
          </div>
        }

        @if (configurableObject.section?.type === EventSectionType.GALLERY){
          <div class="file-container">
            <app-file-uploader
              [aspectRatio]="300 / 157"
              [fluidWidth]="true"
              [resizeToWidth]="1200"
              [useImagePreviewComponent]="true"
              [uploaderType]="'IMAGE'"
              [descriptionAction]='"APP.ADVANCED_ONE_COLUMN_EVENT.UPLOAD_IMAGE"'
              [selectedFile]="form.controls['pictureAsset'].value"
              (fileChanged)="onImageChanged($event)"
            >
            </app-file-uploader>
          </div>
        }

        @if (configurableObject.section?.type === EventSectionType.GROOMSMEN){
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">Name</mat-label>
            <input
              type="text"
              matInput
              formControlName="name"
              placeholder=""
            />
          </mat-form-field>
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">Description</mat-label>
            <input
              type="text"
              matInput
              formControlName="description"
              placeholder=""
            />
          </mat-form-field>
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">{{ "APP.TYPE" | translate }}</mat-label>
            <mat-select formControlName="type">
              @for(option of weddingItemsTypes; track $index){
                <mat-option [value]="option">
                  {{ EventWeddingPartyItemType.getDisplayName(option) | translate }}
                </mat-option>
              }
            </mat-select>

          </mat-form-field>
          <div class="file-container">
            <app-file-uploader
              [aspectRatio]="300 / 157"
              [fluidWidth]="true"
              [resizeToWidth]="1200"
              [useImagePreviewComponent]="true"
              [uploaderType]="'IMAGE'"
              [descriptionAction]='"APP.ADVANCED_ONE_COLUMN_EVENT.UPLOAD_IMAGE"'
              [selectedFile]="form.controls['pictureAsset'].value"
              (fileChanged)="onImageChanged($event)"
            >
            </app-file-uploader>
          </div>
        }
        @if (configurableObject.section?.type === EventSectionType.PICTURE_INFO){
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.TITLE" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="name"
              placeholder=""
            />
          </mat-form-field>
          <mat-form-field
            class="flex-half-shrink flex-input w-100"
            appearance="outline"
          >
            <mat-label class="placeholder">{{ "APP.ADVANCED_ONE_COLUMN_EVENT.DESCRIPTION" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="description"
              placeholder=""
            />
          </mat-form-field>
          <div class="file-container">
            <app-file-uploader
              [aspectRatio]="300 / 157"
              [fluidWidth]="true"
              [resizeToWidth]="1200"
              [useImagePreviewComponent]="true"
              [uploaderType]="'IMAGE'"
              [descriptionAction]='"APP.ADVANCED_ONE_COLUMN_EVENT.UPLOAD_IMAGE"'
              [selectedFile]="form.controls['pictureAsset'].value"
              (fileChanged)="onImageChanged($event)"
            >
            </app-file-uploader>
          </div>
        }

      </div>
    }

    <div class='actions'>
      <div class='buttons'>
        <button class='btn btn-primary-outline' (click)='onClose()'>{{ "APP.CANCEL" | translate }}</button>
        <button class='btn btn-primary' (click)='onSave()'>{{ "APP.SAVE" | translate }}</button>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
